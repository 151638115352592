import React, {useState} from "react";
import {Header, Icon, Menu, Sidebar} from "semantic-ui-react";
import {Media} from "./MediaContext";

export const MobileContainer = props => {
    const {children} = props;
    const [sidebarOpened, handleSidebarHide] = useState(false);

    const phone = `${process.env.REACT_APP_PHONE}`;
    return (
        <Media as={Sidebar.Pushable} at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={val => {
                        handleSidebarHide(!val);
                    }}
                    vertical
                    visible={sidebarOpened}>
                    <Menu.Item as={'a'} active href={"/#/home"}>
                        首页
                    </Menu.Item>
                    <Menu.Item as={'a'} href={"/#/dbg"}>
                        数据治理平台
                    </Menu.Item>
                    <Menu.Item as={'a'} href={"/#/pfs"}>
                        企业绩效管理
                    </Menu.Item>
                    <Menu.Item as={'a'} href={"/#/govopen"}>
                        预决算公开服务
                    </Menu.Item>
                    <Menu.Item as={'a'} href={"/#/contact-us"}>
                        联系我们
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <>
                        <Menu inverted color={"black"} pointing borderless size='mini'>
                            <Menu.Item onClick={() => handleSidebarHide(true)}>
                                <Icon name='sidebar' color={"red"}/>
                            </Menu.Item>
                            <Menu.Item position='right'>
                                {/*<Button as='a' inverted>
                                        Log in
                                    </Button>
                                    <Button as='a' inverted style={{marginLeft: '0.5em'}}>
                                        Sign Up
                                    </Button>*/}
                                <Header as='h4' inverted>欢迎联系我们，张先生，{phone}</Header>
                            </Menu.Item>
                        </Menu>
                    </>
                    {/*<HomepageHeading mobile/>*/}
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    )
}
