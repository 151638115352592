import React, {useState} from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Container,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
} from "semantic-ui-react";
import '../css/contact.css'
import MessageExampleAttached from "../component/form/ContactForm";
import {Map, Marker} from "react-amap";
import {toast} from "react-toastify";
import {inquiry_submit_one} from "../api/inquiry";

const mapCenter = {longitude: 118.695121, latitude: 32.157047};
const markerPosition = {longitude: 118.695121, latitude: 32.157047};

const phone = `${process.env.REACT_APP_PHONE}`;

const ContactUs = props => {

    const [state, setState] = useState({
        msg: '',
        ok: null
    });

    const handleSubmit = (data) => {
        let s_data = {...data};

        inquiry_submit_one(s_data).then(res => {
            /// console.log(res)
            const {ok, msg} = res;
            toast(msg);
            if (!ok) {
                setState({ok: ok, msg: msg});
            }
        }, err => {
            toast(err.message)
        });
    };

    const {ok, msg} = state;

    return (
        <Container fluid>
{/*             <Divider hidden/> */}
{/*             <Divider hidden/> */}
{/*             <Divider hidden/> */}
{/*             <Divider hidden/> */}
            <Grid stackable>
                <GridRow textAlign={"center"} className='contact'>
                    <GridColumn>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Header as='h1' inverted>联系我们</Header>
                        <Header as='h3' inverted>我们欢迎所有用户的宝贵建议与需求反馈</Header>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </GridColumn>

                </GridRow>

                <GridRow columns={3} textAlign={"center"}>
                    <GridColumn width={5}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Icon name={"phone"} size={'huge'}> </Icon>
                        <Header as='h2' size=''>商务合作</Header>
                        <h3>{phone}</h3>
                    </GridColumn>
                    <GridColumn width={5}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Icon name={"wechat"} size={'huge'}> </Icon>
                        <Header as='h2' size=''>技术咨询</Header>
                        <h3>{phone}</h3>
                    </GridColumn>
                    <GridColumn width={6}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Icon name={"mail"} size={'huge'}> </Icon>
                        <Header as='h2' size=''>市场合作</Header>
                        <h3>119633050@qq.com</h3>
                        <Divider hidden/>
                        <Divider hidden/>
                    </GridColumn>
                </GridRow>
                <p>&nbsp;</p>
                <GridRow columns={2} centered>
                    <GridColumn width={5}>
                        <>
                            <MessageExampleAttached
                                ok={ok}
                                msg={msg}
                                handleSubmit={handleSubmit}
                                // link_return={-1}
                            />
                        </>
                    </GridColumn>
                    <GridColumn textAlign={"center"} width={6}>
                        <Card centered style={{width: '400px'}}>
                            <CardContent>
                                <CardHeader textAlign='left'></CardHeader>
                                <CardDescription textAlign={"left"}>南京市浦口区长峰大厦裙楼406</CardDescription>
                                <div style={{width: '100%', height: '20rem'}}>
                                    <Map amapkey={'0eff09ea7b4c3a900ba4dacbb1518c3b'} version='2.0' zoom={14}
                                         plugins={['ToolBar']} center={mapCenter}>
                                        <Marker position={markerPosition}/>
                                    </Map>
                                </div>
                            </CardContent>
                        </Card>
                        {/*<div>*/}
                        {/*    <Header as='h2' textAlign='left'>办公地址：</Header>*/}
                        {/*    <Header as='h3' textAlign='left'>南京市浦口区长峰大厦裙楼406</Header>*/}
                        {/*    <div style={{width: '50%', height: '20rem'}}>*/}
                        {/*        <Map amapkey={'0eff09ea7b4c3a900ba4dacbb1518c3b'} version='2.0' zoom={14}*/}
                        {/*             plugins={['ToolBar']} center={mapCenter}>*/}
                        {/*            <Marker position={markerPosition}/>*/}
                        {/*        </Map>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </GridColumn>
                </GridRow>
                {/*<p>&nbsp;</p>*/}
                {/*<Bottom/>*/}
            </Grid>
        </Container>
    )
}
export default ContactUs;