import React, {useEffect} from "react";
import {
    Button,
    Card,
    CardContent,
    CardDescription,
    CardGroup,
    CardHeader,
    Container,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Image
} from "semantic-ui-react";

import '../../css/home.css'
import {is_login} from "../../common/auth_utils";
import {useNavigate} from "react-router-dom";
import {getQueryParam} from "../../common/search_params";

const HomePage = props => {

    const islogin = is_login() || false;
    const nav = useNavigate();
    const code = getQueryParam("code");
    // console.log(code)
    const login_url_params = code ? ("?code=" + code) : "";
    useEffect(() => {

        // console.log(islogin, code, login_url_params)
        if (!islogin && code) {
            nav('/checklogin' + login_url_params, {replace: true});
        }
    }, [code,]);
    const PUBLIC_URL = process.env.PUBLIC_URL

    return (
        <>
            <Container fluid>
                <Grid>

                    <div className='home'>
                        <Grid.Row>
                            <Header as='h1'
                                    style={{
                                        color: 'white',
                                        fontSize: '4em',
                                        fontWeight: 'large',
                                        marginBottom: 0,
                                        paddingTop: '3em',
                                    }}>数据平台治理方案</Header>
                            <Header
                                as='h2'
                                style={{
                                    color: 'white',
                                    fontSize: '1.7em',
                                    fontWeight: 'normal',
                                    marginTop: '1.5em',
                                    paddingBottom: '7.7em'
                                }}>唯一一个允许在一个平台上无缝跨所有数字渠道运营业务的saas业务平台。</Header>
                        </Grid.Row>

                        <div className='shadow'>
                            <Grid container stackable verticalAlign='middle' textAlign='center'>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <Header as='h1' style={{
                                            color: 'white',
                                            fontSize: '48px',
                                            fontFamily: 'Impact'
                                        }}>300+</Header>
                                        <span style={{color: 'white', fontSize: '20px'}}>合作伙伴</span>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Header as='h1' style={{
                                            color: 'white',
                                            fontSize: '48px',
                                            fontFamily: 'Impact'
                                        }}>1000+</Header>
                                        <span style={{color: 'white', fontSize: '20px'}}>服务政企单位</span>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Header as='h1' style={{
                                            color: 'white',
                                            fontSize: '48px',
                                            fontFamily: 'Impact'
                                        }}>50+</Header>
                                        <span style={{color: 'white', fontSize: '20px'}}>全国客户</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </div>

                    <GridRow textAlign='center'>
                        <GridColumn>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1'>四大核心优势
                            </Header>
                            {/*<div className='line'> </div>*/}
                            <Header as='h4' color='grey' fontWeight='lighter'>
                                Four core advantages
                            </Header>
                            <p>&nbsp;</p>
                        </GridColumn>
                    </GridRow>

                    <GridRow centered>
                        <CardGroup centered>
                            <Card>
                                <div className='icon'>
                                    <CardContent description>
                                        <Image
                                            floated='left'
                                            size='large'
                                            src={`${PUBLIC_URL}/images/home/photo1.png`}

                                        />
                                        <CardHeader textAlign='center'
                                                    style={{
                                                        fontSize: 'x-large',
                                                        paddingBottom: '0.45rem'
                                                    }}>自定义分析</CardHeader>
                                        <CardDescription style={{fontSize: 'small'}}>
                                            量身定制的数据分析解决方案可满足特定的业务需求，为明智的决策提供可操作的见解，并提高整个领域的绩效。
                                        </CardDescription>
                                    </CardContent>
                                </div>
                            </Card>
                            <Card>
                                <div className='icon'>
                                    <CardContent description>
                                        <Image
                                            floated='left'
                                            size='large'
                                            src={`${PUBLIC_URL}/images/home/photo2.png`}
                                        />
                                        <CardHeader textAlign='center'
                                                    style={{
                                                        fontSize: 'x-large',
                                                        paddingBottom: '0.5rem'
                                                    }}>团队管理</CardHeader>
                                        <CardDescription style={{fontSize: 'small', paddingBottom: '1.38rem'}}>
                                            团队管理包括监督一组个人通过协调任务、促进协作和优化生产力来实现共同目标。
                                        </CardDescription>
                                    </CardContent>
                                </div>
                            </Card>
                            <Card>
                                <div className='icon'>
                                    <CardContent description>
                                        <Image
                                            floated='left'
                                            size='large'
                                            src={`${PUBLIC_URL}/images/home/photo3.png`}
                                        />
                                        <CardHeader textAlign='center'
                                                    style={{
                                                        fontSize: 'x-large',
                                                        paddingBottom: '0.45rem'
                                                    }}>建立网站</CardHeader>
                                        <CardDescription style={{fontSize: 'small'}}>
                                            创建网站包括设计和开发网页、内容和功能、以建立用于各种目的的在线存在或平台，因此设计将是最好的。
                                        </CardDescription>
                                    </CardContent>
                                </div>
                            </Card>
                            <Card>
                                <div className='icon'>
                                    <CardContent description>
                                        <Image
                                            floated='left'
                                            size='large'
                                            src={`${PUBLIC_URL}/images/home/photo4.png`}
                                        />
                                        <CardHeader textAlign='center'
                                                    style={{
                                                        fontSize: 'x-large',
                                                        paddingBottom: '0.45rem'
                                                    }}>衡量表现</CardHeader>
                                        <CardDescription style={{fontSize: 'small'}}>
                                            绩效衡量评估任务、流程或个人的有效性和效率，通常使用关键绩效来衡量进展和成功。
                                        </CardDescription>
                                    </CardContent>
                                </div>
                            </Card>
                        </CardGroup>
                    </GridRow>


                    <GridRow textAlign='center'>
                        <GridColumn>
                            <p>&nbsp;</p>
                            <Header as='h1'>发现更多强大功能</Header>
                            <Header as='h3'>通过众多强大功能释放我们平台的力量，让您实现目标。</Header>
                            <p>&nbsp;</p>
                            <Button href='/#' color='blue' circular>更多细节</Button>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </GridColumn>
                    </GridRow>

                    <div className='middle-background' style={{textAlign: 'center'}}>
                        <Grid.Row>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1' inverted>能带来什么</Header>
                            <Header as='h2' inverted>Four core advantages</Header>
                        </Grid.Row>

                        <Grid container stackable verticalAlign='middle' style={{paddingTop: '5em'}}>
                            <Grid.Row>
                                <GridColumn floated='left' width={6}>
                                    <div>
                                        <Image rounded src={`${PUBLIC_URL}/images/perf/photo4.png`} size='xxx-large'/>
                                    </div>
                                </GridColumn>
                                <GridColumn width={8} textAlign='left'>
                                    <Header as='h1' style={{color: 'white', fontSize: '3rem'}}>灵活策略，提升办公效率</Header>
                                    <div className='line'></div>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`} size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>上百条审核公式，维护数据安全</content>
                                    </Header>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`} size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>自定义页面设计，灵活实现需求</content>
                                    </Header>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`}  size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>数据治理功能全覆盖，轻松完成工作</content>
                                    </Header>
                                </GridColumn>

                            </Grid.Row>
                        </Grid>
                        <Grid container stackable verticalAlign='middle' style={{paddingTop: '6em'}}>
                            <Grid.Row>
                                <GridColumn width={8} textAlign='left'>
                                    <Header as='h1'
                                            style={{color: 'white', fontSize: '3rem'}}>绩效管理，帮助企业降本增效</Header>
                                    <div className='line'></div>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`} size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>六大模块，涵盖企业管理的各种要素</content>
                                    </Header>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`}  size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>多种表单一键审批，提高企业运转效率</content>
                                    </Header>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`}  size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>多级考核指标，轻松掌握项目信息</content>
                                    </Header>
                                </GridColumn>
                                <GridColumn floated='right' width={6}>
                                    <div>

                                        <Image rounded src={`${PUBLIC_URL}/images/perf/photo1.png`} size='xxx-large'/>
                                    </div>
                                </GridColumn>
                            </Grid.Row>
                        </Grid>

                        <Grid container stackable verticalAlign='middle'
                              style={{paddingTop: '6em', paddingBottom: '10rem'}}>
                            <Grid.Row>
                                <GridColumn floated='left' width={6}>
                                    <div>

                                        <Image rounded src={`${PUBLIC_URL}/images/perf/photo2.png`} size='xxx-large'/>
                                    </div>
                                </GridColumn>
                                <GridColumn width={8} textAlign='left'>
                                    <Header as='h1' style={{color: 'white', fontSize: '3rem'}}>灵活策略，提升办公效率</Header>
                                    <div className='line'></div>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`} size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>近50种内容分类，一键拦截设置</content>
                                    </Header>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`} size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>自定义拦截与放行，灵活上网管控</content>
                                    </Header>
                                    <Header as='h3' style={{fontSize: '1.5em', color: 'white'}}>
                                        <Image src={`${PUBLIC_URL}/images/home/white-icon.png`} size='large'
                                               style={{paddingRight: '1rem'}}/>
                                        <content>图形化报表功能，轻松纵览全局</content>
                                    </Header>
                                </GridColumn>
                            </Grid.Row>
                        </Grid>
                    </div>

                        <GridRow textAlign='center'>
                            <GridColumn textAlign='center'>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <Header as='h1'>关于我们
                                </Header>
                                <Header as='h7' color='grey' fontWeight='lighter'>
                                    About&nbsp;&nbsp;Us</Header>
                            </GridColumn>
                        </GridRow>

                        <Grid container stackable verticalAlign='middle' celled style={{marginBottom:'5rem'}}>
                            <Grid.Row>
                                <GridColumn floated='right' width={7}>
                                    <div>
                                        <Image bordered rounded src={`${PUBLIC_URL}/images/home/company.png`} size='xxx-large'/>
                                    </div>
                                </GridColumn>
                                <GridColumn width={8} textAlign='left'>
                                    <Header as='h3'>公司简介</Header>
                                    <Header as='h4'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;蓝之梦数据科技江苏有限公司成立于2017年，是专业从事数据治理，提供相关技术服务的高新技术公司，主营业务涉及面向政府单位的预决算公开服务，面向企业机构的绩效管理系统。<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司业务范围横跨全国，包括江苏、安徽、上海、浙江、江西等省份，累计服务政府单位超过1000家。我司在确保服务质量的同时解决了单位的劳务负担，受到了客户的一致认可。<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;秉持着"数据治理，让工作更简单"的使命，公司一直坚持以自主创新的技术，高效周到的服务，专注专一的态度对待每一家客户，全面开创政企数据管理新生态。
                                    </Header>
                                    <Button href='/#/contact-us' primary circular>了解更多</Button>
                                </GridColumn>
                            </Grid.Row>
                        </Grid>

                    <GridRow className='bottom-background' columns={2} textAlign='center'>
                                <GridColumn  width={8}>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <Header as='h2' content='联系我们，了解更多产品与案例详情' style={{color: 'white'}}/>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </GridColumn>
                                <GridColumn width={8} textAlign='center'>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <Button href='/#/contact-us' size='big' circular
                                            style={{backgroundColor: '#FFFFFF', color: 'blue'}}>去留言</Button>
                                </GridColumn>

                    </GridRow>
                </Grid>
            </Container>
        </>
    )
}

export default HomePage;