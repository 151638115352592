/**
 * 网络请求配置
 */
import axios from "axios";
import {get_access_token} from "./auth_utils";

axios.defaults.timeout = 100000;
axios.defaults.withCredentials = false
axios.defaults.headers['Access-Control-Allow-Origin'] = "*";
// axios.defaults.baseURL = "http://127.0.0.1:8080";
//设置默认的提交格式
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config: any) => {
        // config.data = qs.stringify(config.data);
        // config.headers = config.headers || {};
        const token = get_access_token();
        if (token) {
            config.headers['Authorization'] = "Bearer " + token;
            // config.headers['Authorization'] = "JWT " + token;
        }
        // config.headers['Access-Control-Allow-Origin'] = "*";
        // config.headers['Access-Control-Allow-Headers'] = "Origin, X-Requested-With, Content-Type, Accept";

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if (response.status !== 200) {
            return Promise.reject(new Error('网络异常，请稍后重试'));
        }
        return response
    },
    (error) => {
        console.log("请求出错：", error);
        return Promise.reject(error);
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url: string, params = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            headers: headers,
            params: params,
        }).then((response) => {
            landing(url, params, response.data);
            resolve(response.data);
        })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url: string, data = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {headers: headers}).then(
            (response) => {
                //关闭进度条
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url: string, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url: string, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export default function (fecth: string, url: string, param: {}, headers = {}) {
    let _data = "";
    return new Promise((resolve, reject) => {
        switch (fecth.toLowerCase()) {
            case "get":
                console.log("begin a get request,and url:", url);
                get(url, param, headers)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request GET failed.", error);
                        reject(error);
                    });
                break;
            case "post":
                post(url, param, headers)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request POST failed.", error);
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}

//失败提示
function msag(err: any) {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                alert(err.response.data.error.details);
                break;
            case 401:
                alert("未授权，请登录");
                break;

            case 403:
                alert("拒绝访问");
                break;

            case 404:
                alert("请求地址出错");
                break;

            case 408:
                alert("请求超时");
                break;

            case 500:
                alert("服务器内部错误");
                break;

            case 501:
                alert("服务未实现");
                break;

            case 502:
                alert("网关错误");
                break;

            case 503:
                alert("服务不可用");
                break;

            case 504:
                alert("网关超时");
                break;

            case 505:
                alert("HTTP版本不受支持");
                break;
            default:
        }
    }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url: string, params: any, data: any) {
    if (data.code === -1) {
    }
}


