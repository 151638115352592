import React from "react";
import {Grid, GridColumn, Header, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";

const phone = `${process.env.REACT_APP_PHONE}`;

export const Footer = () => {
    const PUBLIC_URL = process.env.PUBLIC_URL
    return (
        <Grid size={"small"} style={{minHeight: '240px', backgroundColor:"black"}} inverted stackable verticalAlign='middle'>
            <GridColumn width={4} textAlign='center'>
                <Image floated='right' size='small' src={`${PUBLIC_URL}/images/logos-white.png`}/>
            </GridColumn>
            <GridColumn width={4} textAlign='center' style={{color: 'white'}}>
                <Header as='h2' inverted>办公地址</Header>
                <Header as='h5' inverted> 南京市浦口区高新技术开发区<br/>星火路14号-长峰大厦裙楼406</Header>
            </GridColumn>
            <GridColumn width={4} textAlign='center'>
                <Header as='h2' inverted>联系我们</Header>
                <Header as='h5' inverted>电话：{phone}</Header>
            </GridColumn>
            <GridColumn width={4} textAlign='center'>
                <a style={{color: 'white', fontSize: '1.75rem', fontWeight: 'bold'}} href='#'>关注我们</a>
                <h4 style={{color: "darkseagreen"}}>蓝之梦数据科技江苏有限公司 版权所有.</h4>
                <Link to="https://beian.miit.gov.cn"
                      target="_blank"> 苏ICP备19008772号</Link>
            </GridColumn>
        </Grid>


    )
}
