const AUTH_KEY = "LZMSJWEB_OAUTH_TOKEN";
const AUTH_TIME = "LZMSJWEB_OAUTH_TIME";

const USER_PROFILE = "LZMSJWEB_USER_PROFILE"

export const store_auth = (token: any, expires = 0) => {
    localStorage.setItem(AUTH_KEY, token);
    let EXPIRES_TIME = new Date().getTime() / 1000 + expires;//记录登录时的时间戳
    // console.log("存储过期时间=", EXPIRES_TIME);
    localStorage.setItem(AUTH_TIME, EXPIRES_TIME + "");
}

export const is_expired = () => {
    let expired_at = localStorage.getItem(AUTH_TIME) || '';
    if (!expired_at) return true;
    // console.log("过期时间判断=", new Date().getTime() / 1000, parseInt(expired_at))
    return new Date().getTime() / 1000 > parseInt(expired_at);
}

export const remove_auth = () => {
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem(AUTH_TIME);
}

export const get_auth = () => {
    let raw_auth = localStorage.getItem(AUTH_KEY);
    return raw_auth;
}

export const is_login = () => {
    return !!get_auth();
}

export const get_access_token = () => {
    const principle = get_auth() || "";
    return principle;
}
export const store_loginuser = (user) => {
    localStorage.setItem(USER_PROFILE, JSON.stringify(user));
}

export const get_loginuser = () => {
    return JSON.parse(localStorage.getItem(USER_PROFILE));
}

