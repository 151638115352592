import React, {useState} from 'react'
import {Button, Form, Message, Segment} from 'semantic-ui-react';
import {useNavigate} from "react-router-dom";

const ChangePasswordForm = (props: any) => {

    const nav = useNavigate();

    const {id, verifyoldpassword, ok, msg, handleSubmit} = props;

    const [data, setData] = useState({
        id: id,
        verifyoldpassword: false,
        oldpassword: '',
        password: '',
        newpassword: '',
    });

    const handleChange = ({name, value}: any) => {
        setData({...data, [name]: value});
    };

    return (
        <Segment>
            <Form error={ok === false}
                  success={ok === true && !!msg}
                  onSubmit={(e: any) => {
                      e.preventDefault();
                      if (data.password !== data.newpassword)
                          return;
                      handleSubmit({id: data.id, oldpassword: data.oldpassword, password: data.password});
                  }}>
                {
                    verifyoldpassword
                    &&
                    <Form.Input
                        size='mini'
                        labelPosition={"left"}
                        label={"原密码"}
                        type="password"
                        name="oldpassword"
                        placeholder="请输入原密码"
                        value={data.oldpassword}
                        onChange={(e: any) => handleChange(e.target)}
                        required
                    />
                }

                <Form.Input
                    size='mini'
                    labelPosition={"left"}
                    label={"新密码"}
                    type="password"
                    name="password"
                    placeholder="请输入新密码"
                    value={data.password}
                    onChange={(e: any) => handleChange(e.target)}
                    required
                />

                <Form.Input
                    size={"mini"}
                    labelPosition={"left"}
                    label={"确认新密码"}
                    type="password"
                    name="newpassword"
                    placeholder="请再次输入新密码"
                    value={data.newpassword}
                    onChange={(e: any) => handleChange(e.target)}
                    required
                />
                {
                    data.password !== data.newpassword &&
                    (
                        <Message color={"red"} content={"两次密码输入不一致！"}/>
                    )
                }

                <Message error>{msg}</Message>
                <Message success>{msg}</Message>

                <Form.Group>
                    <Button primary type="submit">确定</Button>
                    <Button secondary type={"button"} onClick={event => {
                        nav(-1);
                    }}>返回</Button>
                </Form.Group>
            </Form>
        </Segment>
    )
}

export default ChangePasswordForm;
