import React, {useState} from 'react'
import {Button, Divider, Form, FormGroup, FormInput, FormTextArea, Header,} from 'semantic-ui-react'

const MessageExampleAttached = (props: any) => {

    const handleChange = ({name, value}: any) => {
        setData({...data, [name]: value});
    };

    const { ok, msg, handleSubmit} = props;

    const [data, setData] = useState({
        companyname: '',
        contactname: '',
        tel: '',
        email: '',
        content: ''
    });

    return(
        <>

            <Form
                size='small'
                  error={ok === false}
                  success={ok === true && !!msg}
                  onSubmit={(e: any) => {
                      e.preventDefault();
                      handleSubmit({ companyname: data.companyname, contactname: data.contactname,
                          tel: data.tel,email: data.email,content: data.content});
                  }}>
                <Header
                    as={"h3"}
                    block
                    textAlign={"center"}
                    attached={"top"}
                >留下您的需求，以便我们及时联系您!</Header>
                <Divider hidden/>
                <FormGroup widths='equal'>
                    <FormInput
                        fluid
                        size='small'
                        label='公司名称'
                        placeholder='请输入公司名称'
                        // labelPosition={"left"}
                        name="companyname"
                        value={data.companyname}
                        onChange={(e: any) => handleChange(e.target)}
                        required/>

                    <FormInput
                        fluid
                        size='small'
                        label='联系人'
                        placeholder='请输入联系人姓名'
                        name="contactname"
                        value={data.contactname}
                        onChange={(e: any) => handleChange(e.target)}
                        required
                    />
                </FormGroup>
                <FormGroup widths='equal'>
                    <FormInput
                        id='tel'
                        size='small'
                        fluid
                        label='电话'
                        placeholder='请输入联系电话'
                        type='text'
                        name="tel"
                        value={data.tel}
                        onChange={(e: any) => handleChange(e.target)}
                        required

                    />
                    <FormInput
                        id='email'
                        size='small'
                        fluid
                        label='email'
                        placeholder='请输入联系Email'
                        type='text'
                        name="email"
                        value={data.email}
                        onChange={(e: any) => handleChange(e.target)}
                        // required
                    />
                </FormGroup>
                <FormTextArea size='small'
                              label='咨询内容'
                              placeholder='请输入您的需求'
                              name="content"
                              value={data.content}
                              onChange={(e: any) => handleChange(e.target)}
                              required/>
                <Button primary>提交</Button>
            </Form>
        </>
    )
}


export default MessageExampleAttached