import React from 'react';
import styled from 'styled-components';

const ProductAdvantageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
`;

const AdvantageItem = styled.div`
  display: flex;
  align-items: center;
`;

const AdvantageIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

const AdvantageContent = styled.div`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ProductAdvantage = () => {
  const advantages = [
    {
      icon: '/images/advantage1.png',
      title: '优势1',
      content: '优势1的详细描述...'
    },
    {
      icon: '/images/advantage2.png',
      title: '优势2',
      content: '优势2的详细描述...'
    },
    {
      icon: '/images/advantage3.png',
      title: '优势3',
      content: '优势3的详细描述...'
    },
    {
      icon: '/images/advantage4.png',
      title: '优势4',
      content: '优势4的详细描述...'
    },
    {
      icon: '/images/advantage5.png',
      title: '优势5',
      content: '优势5的详细描述...'
    },
    {
      icon: '/images/advantage6.png',
      title: '优势6',
      content: '优势6的详细描述...'
    }
  ];

  return (
    <ProductAdvantageWrapper>
      {advantages.map((advantage, index) => (
        <AdvantageItem key={index}>
          <AdvantageIcon src={advantage.icon} alt={`Advantage ${index + 1}`} />
          <AdvantageContent>
            <h3>{advantage.title}</h3>
            <p>{advantage.content}</p>
          </AdvantageContent>
        </AdvantageItem>
      ))}
    </ProductAdvantageWrapper>
  );
};

export default ProductAdvantage;