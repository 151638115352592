import {Container,} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {inquiry_del_by_id, inquiry_get_all_items} from "../../api/inquiry";
import {Column} from "react-base-table";
import {RichTable} from "../../component/table/RichTable";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";

const InquiryInfo = props => {
    const [rand, setRand] = useState(0);

    const [sort, setSort] = useState({
        sortfield: 'dtsubmitted',
        sortdir: 'desc'
    });

    const columnSort = (field, dir) => {
        setSort({sortfield: field, sortdir: dir});
    }

    const [page, setPage] = useState({
        activePage: 1,
        pageSize: 10,
        totalPage: 0,
        totalElements: 0,
        pageItems: []
    });

    const onPageChange = (currentPage, totalPage, pageSize) => {
        if (currentPage > totalPage) currentPage = totalPage;
        else if (currentPage < 1) currentPage = 1;
        setPage({...page, activePage: currentPage});
        setRand(Math.random() * 10000)
    }

    useEffect(() => {

        inquiry_get_all_items(null, sort.sortfield, sort.sortdir, page.activePage, page.pageSize).then(res => {
            const {content = [], totalPages = 0, size = 10, number = 0} = res;
            setPage({...page, pageItems: content, pageSize: size, totalPage: totalPages});
        });

    }, [rand]);

    return (
        <Container style={{height: '700px'}}>
            <RichTable pageable={true} {...page} items={page.pageItems}
                       style={{height: "calc(100vh - 280px)"}}
                       columnSort={(field, sort) => {
                           columnSort(field, sort);
                           setRand(Math.random() * 10000);
                       }} sortBy={{key: sort.sortfield, order: sort.sortdir || ''}}
                       setPageSize={pagesize => setPage({...page, pageSize: pagesize})}
                       onPageChange={onPageChange}>
                <Column key={"action01"} title={"操 作"} width={80} align={Column.Alignment.CENTER}
                        style={{color: 'black'}}
                        frozen={Column.FrozenDirection.LEFT}
                        cellRenderer={({rowData}) => (
                            <NavLink to={null} onClick={e => {
                                e.preventDefault();
                                let ok = window.confirm("是否确定删除？");
                                if (!ok) return;
                                inquiry_del_by_id(rowData.id).then(res => {
                                    const {data, ok, msg} = res;
                                    if (ok) {
                                        toast("删除成功")
                                        setRand(Math.random() * 10000)
                                    }
                                }, err => {
                                    toast(err.message)
                                })
                            }} style={{color: 'blue'}}>删除</NavLink>
                        )}>
                </Column>
                <Column title={"公司名称"} key="companyname" dataKey="companyname" sortable resizable align={"left"}
                        width={150}/>
                <Column title={"联系人"} key="contactname" dataKey="contactname" sortable resizable align={"left"}
                        width={150}/>
                <Column title={"电话"} key="tel" dataKey="tel" sortable resizable align={"left"} width={150}/>
                <Column title={"提交时间"} key="dtsubmitted" dataKey="dtsubmitted" sortable resizable align={"left"}
                        width={150}/>
                <Column title={"邮箱"} key="email" dataKey="email" sortable resizable align={"left"} width={150}/>
                <Column title={"咨询内容"} key="content" dataKey="content" sortable resizable align={"left"} width={370}/>

            </RichTable>
        </Container>
    )
}

export default InquiryInfo;