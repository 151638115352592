import React, {useEffect} from 'react';
import {
    Card,
    CardContent,
    CardDescription,
    CardGroup,
    CardHeader,
    Container,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Image
} from "semantic-ui-react";
import '../css/perf.css'

//绩效管理系统
function PerfModule() {

    useEffect(() => {
    }, []);

    const PUBLIC_URL = process.env.PUBLIC_URL

    return (
        <>
            <Container fluid>
                <Grid className='content-container'>
                    <GridRow className='perf' textAlign='center'>
                        <GridColumn width={2}></GridColumn>
                        <GridColumn width={11}>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1' size={"huge"} textAlign={"center"}>企业绩效管理云系统（企业绩效在线管理系统）
                            </Header>
                            <Header as='h3' textAlign={"center"} style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>总体介绍：我司从中小企业管理的实际需求出发，开发了一套适合中小企业降本增效的解决信息化方案。通过将企业制度以绩效管理云系统的方式落地执行，帮助中小企业提高企业治理水平。我们认为：管理也是生产力</Header>
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn>
                            <p>&nbsp;</p>
                            <Header as='h1' size={"huge"} textAlign={"center"}>如何帮助企业提高效益？
                            </Header>
                            <Header as='h3' textAlign='center'>绩效管理系统将为企业提供一站式的大数据分析平台，帮助企业高效率、低成本的建立数据应用体系，其灵活、易用、高性能和一站式的特性，<br/>
                                让企业轻松实现数据驱动的精细化运营，全面激活业务增长，提升经营绩效！</Header>
                        </GridColumn>
                    </GridRow>


                    {/*<div style={{backgroundColor: '#FBFCFF'}}>*/}
                    {/*    <Grid container stackable verticalAlign='middle' style={{paddingTop: '5em'}}>*/}

                    <Grid.Row columns={3} >
                        <GridColumn width={2}>
                            <p>&nbsp;</p>
                        </GridColumn>
                        <GridColumn width={6} textAlign='center'>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1'>系统管理</Header>
                            {/*<div className='line'></div>*/}
                            {/*<Header as='h1'>合同流程</Header>*/}
                            <Header as='h3'>
                                系统包含了合同管理（自定义流程、合同执行监控）、项目管理（自定义流程、项目任务、项目考勤）、人事考勤（请假、加班、差旅申请等、考勤同步、加班时间自动计算）、费用管理、人员考核、报表查询等功能模块。
                            </Header>
                        </GridColumn>
                        <GridColumn width={8} textAlign='center'>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Image centered rounded src={`${PUBLIC_URL}/images/perf/photo1.png`} size='large'/>
                        </GridColumn>
                    </Grid.Row>
                    {/*    </Grid>*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <Grid container stackable verticalAlign='middle' style={{paddingTop: '5em'}}>*/}
                    <Grid.Row columns={2} >
                        <GridColumn width={8} >
                            <p>&nbsp;</p>
                            <Image centered rounded src={`${PUBLIC_URL}/images/perf/photo2.png`} size='large'/>
                        </GridColumn>
                        <GridColumn width={6} textAlign='center'>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1'>云系统、全流程服务</Header>
                            {/*<div className='line'></div>*/}
                            {/*<Header as='h1'>项目流程</Header>*/}
                            <h3>
                                不同于传统软件需要采购软硬件、安装部署、实施、测试上线。我们采用腾讯云部署，通过浏览器访问，无需安装部署，上线快捷，随处皆可访问。我们采用新型的客户服务模式，专属群、专人负责，服务期内免费解决您的问题（如需要现场服务会产生一定费用），我们努力成为您的信息化帮手。
                            </h3>
                        </GridColumn>

                    </Grid.Row>
                    {/*    </Grid>*/}
                    {/*</div>*/}

                    {/*<div style={{backgroundColor: '#FBFCFF'}}>*/}
                    {/*    <Grid container stackable verticalAlign='middle' style={{paddingTop: '5em'}}>*/}
                    <Grid.Row columns={3} >
                        <GridColumn width={2}>
                            <p>&nbsp;</p>
                        </GridColumn>
                        <GridColumn width={6} textAlign='center' >
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1'>数据安全</Header>
                            <Header as='h3'>
                                登录需要用户名和密码，密码加密存储。采用https安全协议访问。另外，每个客户的数据可以选择单独存储，系统为多租户架构。
                            </Header>
                        </GridColumn>
                        <GridColumn  width={8}>
                            <p>&nbsp;</p>
                            <Image centered rounded src={`${PUBLIC_URL}/images/perf/photo3.png`} size='large'/>
                        </GridColumn>
                    </Grid.Row>
                    {/*    </Grid>*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <Grid container stackable verticalAlign='middle' style={{paddingTop: '5em'}}>*/}
                    <Grid.Row columns={2}>
                        <GridColumn  width={8}>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Image centered rounded src={`${PUBLIC_URL}/images/perf/photo4.png`} size='large'/>
                        </GridColumn>
                        <GridColumn width={6} textAlign='center'>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Header as='h1'>移动端支持</Header>
                            <Header as='h3'>
                                系统支持移动端访问、小程序访问。目前正在开发中。
                            </Header>
                        </GridColumn>
                    </Grid.Row>

                    <p>&nbsp;</p>

                    <GridRow textAlign='center' className="background-row">
                        <GridColumn>
                            <p>&nbsp;</p>
                            <Header as='h1'>助力有效管理业务增长
                            </Header>
                            {/*<div className='line'> </div>*/}
                            <Header as='h4' color='grey' fontWeight='lighter'>
                                Four core advantages
                            </Header>
                            <p>&nbsp;</p>
                        </GridColumn>
                    </GridRow>

                    <GridRow centered className="background-row">
                        <CardGroup centered='centered'>
                            <Card style={{padding: '1.5rem', width: '26rem'}}>
                                <CardContent style={{paddingTop: '1.5rem'}} description>
                                    <Image
                                        floated='left'
                                        size='mini'
                                        src={`${PUBLIC_URL}/images/perf/icon1.png`}
                                    />
                                    <CardHeader textAlign='left'
                                                style={{fontSize: 'x-large', paddingTop: '0.4rem'}}>客户和合同管理</CardHeader>
                                    <CardDescription style={{fontSize: 'small'}}>
                                        不论您有多少用户，有了客户和合同流程管理，客户关键变得简单轻松。回款状态清晰可见。
                                    </CardDescription>
                                </CardContent>
                            </Card>
                            <Card style={{padding: '1.5rem', alignItems: 'center', width: '26rem'}}>

                                <CardContent style={{paddingTop: '1.5rem'}} description>
                                    <Image
                                        floated='middle'
                                        size='mini'
                                        src={`${PUBLIC_URL}/images/perf/icon2.png`}
                                    />
                                    <CardHeader textAlign='left'
                                                style={{
                                                    fontSize: 'x-large',
                                                    paddingTop: '0.4rem'
                                                }}>提高效率、节省时间</CardHeader>
                                    <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                        模块设计本着只录入一次原则，各个模块间流程关联，有效减少工作量。提供模糊查询功能，可以减少您查找文件的时间。
                                    </CardDescription>
                                </CardContent>
                            </Card>
                            <Card style={{padding: '1.5rem', alignItems: 'center', width: '26rem'}}>

                                <CardContent style={{paddingTop: '1.5rem'}} description>
                                    <Image
                                        floated='middle'
                                        size='mini'
                                        src={`${PUBLIC_URL}/images/perf/icon3.png`}
                                    />
                                    <CardHeader textAlign='left'
                                                style={{fontSize: 'x-large', paddingTop: '0.4rem'}}>节省开支</CardHeader>
                                    <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                        相比一大笔资金预算采购软件，我们采用按年、按量付费模式，投入少、见效快，避免资金浪费。
                                    </CardDescription>
                                </CardContent>
                            </Card>
                        </CardGroup>
                    </GridRow>

                    <GridRow centered className="background-row">
                        <CardGroup centered='centered'>
                            <Card style={{padding: '1.5rem', alignItems: 'center', width: '26rem'}}>

                                <CardContent style={{paddingTop: '1.5rem'}} description>
                                    <Image
                                        floated='middle'
                                        size='mini'
                                        src={`${PUBLIC_URL}/images/perf/icon4.png`}
                                    />
                                    <CardHeader textAlign='left'
                                                style={{fontSize: 'x-large', paddingTop: '0.4rem'}}>团队沟通</CardHeader>
                                    <CardDescription style={{ontSize: 'small'}}>
                                        项目往往多人协作完成，项目流程正是满足该场景。
                                    </CardDescription>
                                </CardContent>
                            </Card>
                            <Card style={{padding: '1.5rem', alignItems: 'center', width: '26rem'}}>

                                <CardContent style={{paddingTop: '1.5rem'}} description>
                                    <Image
                                        floated='middle'
                                        size='mini'
                                        src={`${PUBLIC_URL}/images/perf/icon6.png`}
                                    />
                                    <CardHeader textAlign='left'
                                                style={{fontSize: 'x-large', paddingTop: '0.4rem'}}>数据安全</CardHeader>
                                    <CardDescription style={{fontSize: 'small'}}>
                                        每个客户的数据独立的数据库存储，互不干扰；采用https安全协议进行数据传输；用户访问采用oauth2协议。
                                    </CardDescription>
                                </CardContent>
                            </Card>
                            <Card style={{padding: '1.5rem', alignItems: 'center', width: '26rem'}}>
                                <CardContent style={{paddingTop: '1.5rem'}} description>
                                    <Image
                                        floated='middle'
                                        size='mini'
                                        src={`${PUBLIC_URL}/images/perf/icon6.png`}
                                    />
                                    <CardHeader textAlign='left'
                                                style={{fontSize: 'x-large', paddingTop: '0.4rem'}}>过程跟进</CardHeader>
                                    <CardDescription style={{fontSize: 'small'}}>
                                        无论合同流程还是项目流程，目标之一都是为了及时跟进合同或项目进度，达到阶段目标，促进及时完成。
                                    </CardDescription>
                                </CardContent>
                            </Card>
                        </CardGroup>
                    </GridRow>

                    <GridRow centered className="background-row">
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '26rem'}} centered='centered'>
                            <CardContent style={{paddingTop: '1.5rem'}} description>
                                <Image
                                    floated='middle'
                                    size='mini'
                                    src={`${PUBLIC_URL}/images/perf/icon7.png`}
                                />
                                <CardHeader textAlign='left'
                                            style={{fontSize: 'x-large', paddingTop: '0.4rem'}}>客户支持</CardHeader>
                                <CardDescription style={{fontSize: 'small'}}>
                                    我们从接触客户的那一刻开始，就已处于待命状态，及时提供技术支持和服务。在整个使用周期内，指定客户经理和专门技术人员负责。
                                </CardDescription>
                            </CardContent>
                        </Card>
                        <Divider hidden/>
                    </GridRow>
                </Grid>
            </Container>

        </>
    )
}

export default PerfModule;