import http from '../common/http';
// const AUTH_PATH = `${process.env.REACT_APP_AUTHPATH}`
const AUTH_PATH = `${process.env.REACT_APP_SSO}/api`
//用户自行修改密码
export function change_myself_password(obj: any) {
    return http("POST", `${AUTH_PATH}/myselfchangepwd`, obj);
}

//加载用户
export function user_load_profile() {
    return http("GET", `${AUTH_PATH}/profile`, {});
}

export function user_save_profile(obj: any) {
    return http("POST", `${AUTH_PATH}/profile`, obj);
}


