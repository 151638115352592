import BaseTable, {AutoResizer} from "react-base-table";
import {Pagination, Select} from "semantic-ui-react";

export const RichTable = (props) => {
    // console.log(props)
    const {
        pageable = false, children, items,
        totalElements = 0,
        totalPage = 1, activePage = 1, pageSize = 20, setPageSize = pagesize => {
        }, onPageChange,
        style = {}, columnSort = (field, dir) => {
        }, sortBy
    } = props;

    return (<>
        <div style={{...style}}>
            <AutoResizer>
                {({width, height}) => (
                    <BaseTable
                        fixed
                        sortBy={sortBy}
                        onColumnSort={
                            ({key, order}) => columnSort(key, order)
                        }
                        width={width} height={height}
                        data={items} rowKey={"id"}>
                        {children}
                    </BaseTable>
                )}
            </AutoResizer>
        </div>
        {
            pageable === true && <div style={{width: '100%', fontWeight: "bold", verticalAlign: "middle"}}>
                <span>共{totalPage}页，{totalElements || totalPage * pageSize}记录，&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <Select style={{fontWeight: "bold"}} label={"每页数量："}
                              options={[{text: "20条", value: 20}, {text: "50条", value: 50}, {
                                  text: "100条",
                                  value: 100
                              }, {text: "200条", value: 200}, {text: "500条", value: 500},]}
                              value={pageSize}
                              onChange={(name, value) => {
                                  setPageSize(value);
                                  onPageChange(1, totalPage, value);
                              }}/>
                <Pagination style={{float: "right"}}
                            totalPages={totalPage}
                            activePage={activePage}
                            onPageChange={(event, data) => {
                                onPageChange(data.activePage || 1, totalPage, pageSize);
                            }}/>
            </div>
        }
    </>)
}
