import React from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardGroup,
    CardHeader,
    Container,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Image
} from "semantic-ui-react";
import '../css/dbg.css'
import PageScenariosDbg from './PageScenarios/PageScenariosDbg';


const PUBLIC_URL = process.env.PUBLIC_URL
function DataBusinessGovernment() {
    return (

        <Container fluid >
            <Grid padded>
                <Grid.Row textAlign='center' className='dbg' columns={2}>
                    <GridColumn >
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Header as='h1'>数据治理平台</Header>
                        <Header as='h3' textAlign='left' style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>我们提供了在线数据治理（管理）SaaS服务，不同于传统软件，无需安装。我们根据客户业务特点，定制填报系统、设计审核规则，从源头管控数据，帮助企业在数据构建和应用的过程中实现降本增效，数据价值最大化。</Header>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </GridColumn>
                </Grid.Row>

                <GridRow textAlign='center' className="background-row">
                    <GridColumn>
                        <p>&nbsp;</p>
                        <h1>系统展示</h1>
                    </GridColumn>
                </GridRow>
                <GridRow textAlign='center' className="background-row">
                  <GridColumn width={16} centered>
                    <PageScenariosDbg />
                  </GridColumn>
                </GridRow>

                <GridRow textAlign='center' >
                    <GridColumn>
                        <p>&nbsp;</p>
                        <Header as='h1'>方案优势
                        </Header>
                        <p>&nbsp;</p>
                    </GridColumn>
                </GridRow>
                <GridRow centered >
                    <CardGroup centered>
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '24rem', backgroundImage: `url(${PUBLIC_URL}/images/dbg/bg_card.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <Image
                                floated='middle'
                                size='tiny'
                                src={`${PUBLIC_URL}/images/dbg/icon1.png`}
                            />
                            <CardContent style={{paddingTop: '1.5rem'}} description>

                                <CardHeader textAlign='center' style={{fontSize: 'x-large'}}>表格化自定义业务</CardHeader>
                                <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                    支持样式定义、变量定义等。支持大部分Excel公式。
                                </CardDescription>
                            </CardContent>
                        </Card>
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '24rem', backgroundImage: `url(${PUBLIC_URL}/images/dbg/bg_card.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <Image
                                floated='middle'
                                size='tiny'
                                src={`${PUBLIC_URL}/images/dbg/icon2.png`}
                            />
                            <CardContent style={{paddingTop: '1.5rem'}} description>

                                <CardHeader textAlign='center' style={{fontSize: 'x-large'}}>源头数据治理、全过程数据治理</CardHeader>
                                <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                    不同于传统的事后数据治理解决方案。我们将数据治理融入到业务中，实现“事前、事中、事后”数据治理；从源头控制数据填报质量，用户在填报阶段自审核通过后方能报送数据。
                                </CardDescription>
                            </CardContent>
                        </Card>
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '24rem', backgroundImage: `url(${PUBLIC_URL}/images/dbg/bg_card.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <Image
                                floated='middle'
                                size='tiny'
                                src={`${PUBLIC_URL}/images/dbg/icon3.png`}
                            />
                            <CardContent style={{paddingTop: '1.5rem'}} description>

                                <CardHeader textAlign='center'
                                            style={{fontSize: 'x-large'}}>自定义审核公式</CardHeader>
                                <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                    审核公式完全在线自定义，同时支持python脚本计算。支持正则表达式、行合计审核等复杂审核。
                                </CardDescription>
                            </CardContent>
                        </Card>
                    </CardGroup>

                </GridRow>

                <GridRow centered >
                    <CardGroup centered style={{paddingBottom: '10rem'}}>
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '24rem', backgroundImage: `url(${PUBLIC_URL}/images/dbg/bg_card.png)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <Image
                                floated='middle'
                                size='tiny'
                                src={`${PUBLIC_URL}/images/dbg/icon4.png`}
                            />
                            <CardContent style={{paddingTop: '1.5rem'}} description>

                                <CardHeader textAlign='center' style={{fontSize: 'x-large'}}>自定义输出、查询</CardHeader>
                                <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                    支持自定义取值定义、复杂条件计算取数，支持Word模板自定义和报告渲染，输出符合要求的报告；支持定义查询、跨表查询。
                                </CardDescription>
                            </CardContent>
                        </Card>
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '24rem', backgroundImage: `url(${PUBLIC_URL}/images/dbg/bg_card.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <Image
                                floated='middle'
                                size='tiny'
                                src={`${PUBLIC_URL}/images/dbg/icon5.png`}
                            />
                            <CardContent style={{paddingTop: '1.5rem'}} description>

                                <CardHeader textAlign='center' style={{fontSize: 'x-large'}}>多租户支持</CardHeader>
                                <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                    您的数据存储完全独立于其他客户，专有存储。方便您的数据持续利用～
                                </CardDescription>
                            </CardContent>
                        </Card>
                        <Card style={{padding: '1.5rem', alignItems: 'center', width: '24rem', backgroundImage: `url(${PUBLIC_URL}/images/dbg/bg_card.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <Image
                                floated='middle'
                                size='tiny'
                                src={`${PUBLIC_URL}/images/dbg/icon6.png`}
                            />
                            <CardContent style={{paddingTop: '1.5rem'}} description>

                                <CardHeader textAlign='center' style={{fontSize: 'x-large'}}>数据安全</CardHeader>
                                <CardDescription style={{paddingTop: '0.5rem', fontSize: 'small'}}>
                                    采用OAuth安全协议，登录后获取token进行数据操作；传输采用https安全传输；这些措施保证数据安全。
                                </CardDescription>
                            </CardContent>
                        </Card>
                    </CardGroup>
                </GridRow>

            </Grid>
        </Container>

    )
}

export default DataBusinessGovernment;