import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  flex: 1;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: ${(props) => (props.active ? '#007bff' : '#f1f1f1')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 2rem;
`;

const ImageWrapper = styled.div`
  flex: 0 0 60%;
  margin-right: 2rem;

//    @media (max-width: 768px) {
//     flex: 0 0 auto;
//     margin-right: 0;
//     margin-bottom: 1rem;
//   }
//
//   img {
//     max-width: 100%;
//     height: auto;
//   }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextWrapper = styled.div`
  flex: 0 0 40%;
  text-align: left;
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  padding: 2rem;
`;




const PageScenariosGovOpen = () => {
  const [activeScenario, setActiveScenario] = useState('finance');

  const handleScenarioClick = (scenario) => {
    setActiveScenario(scenario);
  };

  const scenarios = {
    finance: {
      title: '自定义模板',
      content: '极其灵活的业务系统可以满足地区单位的个性化需求 ',
      con1: '* 根据省标准套表和公开模板要求，为各个区县配制专属模板 ',
      con2: '* 新增绩效情况表、下属单位表、单位职能表等。完整涵盖了模板所需的全部数据',
      image: `${process.env.PUBLIC_URL}/images/gov_open/gov-open-1.png`,
    },
    transportation: {
      title: '快速生成报告',
      content: '最快一个小时内即可生成一份完整的报告，大大节约了时间和人力成本.',
      con1: '根据客户需求，线上或线下培训，支持上千家单位同时填报。',
      con2: '根据各个地区口径不同，可自定义审核公式，无需修改代码即可实现，提高报告整体质量。',
      image: `${process.env.PUBLIC_URL}/images/gov_open/gov-open-2.png`,

    },
    ecommerce: {
      title: '持续跟踪服务',
      content: '我司会对单位生成的报告进行多轮检查，以及对公开状态的追踪，为客户提供最完善的服务。',
      con1: '报告完成后，后续会对报告中进行多轮的检查核对，并给各单位发送问题报告，以防出现填写的不合理、引用文件有误等问题。',
      con2: '我们拥有自主研发的报告跟踪系统，会持续对客户的报告挂网情况进行跟踪及版本比对，避免出现公开不及时，网址失效等情况。',
      image: `${process.env.PUBLIC_URL}/images/gov_open/gov-open-3.png`,

    },
  };

  return (
    <Wrapper>
      <ButtonGroup>
        {Object.keys(scenarios).map((scenario) => (
          <Button
            key={scenario}
            onClick={() => handleScenarioClick(scenario)}
            active={activeScenario === scenario}
          >
            {scenarios[scenario].title}
          </Button>
        ))}
      </ButtonGroup>
     <ContentRow>
      <ImageWrapper>
        <Image src={scenarios[activeScenario].image} alt="Example" />
      </ImageWrapper>
      <TextWrapper>
        <h2>{scenarios[activeScenario].title}</h2>
        <p>{scenarios[activeScenario].content}</p>
        <p>{scenarios[activeScenario].con1}</p>
        <p>{scenarios[activeScenario].con2}</p>
      </TextWrapper>
    </ContentRow>
    </Wrapper>
  );
};

export default PageScenariosGovOpen;