import React from "react";
import {Column} from 'react-base-table'
import {Button} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {module_delete_one} from "../../api/module";
import {useStore} from "../../store";
import {RichTable} from "../../component/table/RichTable";
import "react-base-table/styles.css"

const DOMAIN_NAME = "/admin/module";
const ModuleTable = props => {
    const {setRand=val=>{}, sort, columnSort, page, onPageChange, height} = props;

    const nav = useNavigate();

    const {globalstate} = useStore();
    return (
        <>
            <RichTable
                style={{height: height ? height : "calc(100vh - 220px)"}}
                {...page} onPageChange={onPageChange}
                columnSort={columnSort} sortBy={{key: sort.sortfield, order: sort.sortdir || ''}}>
                <Column hidden={true} key="id" title={"ID"} dataKey="id" width={0}/>
                <Column key="modulename" title={"模块名称"} sortable resizable dataKey="modulename" width={200}/>
                {/*
                <Column key="authorities" title={"权限定义"} resizable width={100}
                        cellRenderer={({rowData}) => (rowData?.authorities?.length || 0) + "个"}/>
                */}
                <Column key="remark" title={"模块说明"} dataKey="remark" width={150}/>
                <Column key={"action"} title={"操作"} width={220} align={Column.Alignment.CENTER}
                        frozen={Column.FrozenDirection.LEFT}
                        cellRenderer={({rowData}) => (
                            <>
                                <Button size={"mini"} primary onClick={() => {
                                    nav(`${DOMAIN_NAME}/edit/${rowData.id}`);
                                }}>编辑</Button>
                                {
                                    globalstate.hasAuthority("sso:module:delete") &&
                                    <Button size={"mini"} color={"red"} onClick={(event) => {
                                        if (!window.confirm("确定要删除？"))
                                            return;
                                        module_delete_one(rowData.id).then(res => {
                                            const {ok, msg} = res;
                                            toast(msg);
                                            if (ok) {
                                                setRand(Math.random() * 10000);
                                            }
                                        }, err => {
                                            toast(err.message);
                                        })
                                    }}>删除</Button>
                                }
                            </>
                        )}>
                </Column>
            </RichTable>
        </>
    );
}

export default ModuleTable;
