import React, {useEffect, useState} from "react";
import {Button, Form, Header, Message} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {module_load_by_id, module_save_one} from "../../api/module";

const ModuleForm = (props) => {

    const {id, title} = props;

    let navigate = useNavigate();

    const [obj, setObj] = useState({});

    const [status, setStatus] = useState({
        ok: null,
        msg: ''
    });

    useEffect(() => {
        if (!id) return;
        module_load_by_id(id).then(res => {
            // console.log(res)
            const {ok, msg, data} = res;
            setStatus({ok, msg});
            if(ok) {
                setObj({...data});
            }

        });
    }, [id]);


    const handleChange = ({name, value}) => {
        setObj({...obj, [name]: value});
    };

    return (
        <>
            <Header>{title}</Header>
            <Form
                error={status.ok === false}
                success={status.ok === true && !!status.msg}
                onSubmit={(e) => {
                    e.preventDefault();
                    module_save_one({
                        ...obj
                    }).then(res => {
                        const {ok, msg} = res;
                        if (ok) {
                            navigate(-1);
                        } else {
                            toast(msg);
                        }
                    });
                }}>
                <Form.Input type={"hidden"} name={"id"} value={obj?.id || null}/>
                <Form.Input
                    required
                    name="modulename"
                    label={"模块名称："}
                    placeholder="可以是子系统、模块等名称"
                    value={obj.modulename || ""}
                    onChange={(e) => handleChange(e.target)}></Form.Input>

                <Form.TextArea
                    // inline
                    name="remark"
                    label={"备注："}
                    placeholder="请详细说明角色细节！"
                    value={obj.remark || ""}
                    onChange={(e) => handleChange(e.target)}></Form.TextArea>

                <Message error>
                    {status.msg}
                </Message>

                <Form.Group widths={"equal"} style={{padding: "8px"}}>
                    <Button primary type="submit">
                        保存
                    </Button>

                    <Button type={"button"} onClick={(event) => navigate(-1)} secondary content={"返回"}/>
                </Form.Group>
            </Form>
        </>
    );
};

export default ModuleForm;
