import React from "react";
import {
    Container,
    Grid,
    GridColumn,
    GridRow,
    Header,
} from "semantic-ui-react";

import '../css/lowcode.css'
import ProductAdvantage from './ProductAdvantage';
import PageScenariosLowCode from './PageScenarios/PageScenariosLowCode';

const PUBLIC_URL = process.env.PUBLIC_URL
const LowCode = () => {
    return (
        <Container fluid >
            <Grid padded>
                <Grid.Row textAlign='center' className='lc' columns={3} >
                    <GridColumn width={2}></GridColumn>
                    <GridColumn width={12}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Header as='h1' inverted>低代码特点</Header>
                        <Header as='h3' inverted>我们提供了套表为独立业务单元（例如CRM），
                            表间关系通过外键定义，审核公式限定在单表上，支持自定义查询，数据权限控制复杂等，
                            通过系统配置即可快速上线。相较于其他厂商的低代码解决方案，我司提供的方案更加简单、便捷、更接近业务。实施风险低。</Header>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </GridColumn>
                </Grid.Row>


                <GridRow>
                  <GridColumn width={16}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ maxWidth: '1400px', width: '100%' }}>
                        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                          <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>产品优势</h2>
                          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp1.png`} alt="Advantage 1" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">表格化自定义</h3>
                                <p>支持样式、变量、外键、菜单定义以及单元格类型等。支持大部分Excel公式。</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp2.png`} alt="Advantage 2" className='image_min' />
                                <div style={{marginLeft: '30px'}}>
                                    <h3 className="product_ad_h3">自定义审核公式</h3>
                                    <p>对于每个业务表,我们定义了一系列审核公式,确保填报内容符合业务要求。</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp3.png`} alt="Advantage 3" className='image_min' />
                                <div style={{marginLeft: '30px'}}>
                                    <h3 className="product_ad_h3">丰富的查询</h3>
                                    <p>支持设置业务表默认过滤条件、支持自动或手动关联多表、聚合统计功能等。</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp6.png`} alt="Advantage 4" className='image_min' />
                                <div style={{marginLeft: '30px'}}>
                                    <h3 className="product_ad_h3">开箱即用云服务</h3>
                                    <p>即开即用，只需要几分钟即可一键开启您的数据价值洞察之旅。</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp5.png`} alt="Advantage 5" className='image_min' />
                                <div style={{marginLeft: '30px'}}>
                                    <h3 className="product_ad_h3">多租户架构</h3>
                                    <p>应用系统中支持多个租户(客户)独立使用，多租户为企业带来显著的成本优势和运营效率提升。</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp4.png`} alt="Advantage 6" className='image_min' />
                                <div style={{marginLeft: '30px'}}>
                                    <h3 className="product_ad_h3">专属服务模式</h3>
                                    <p>专属服务模式能够最大限度地满足客户的个性化需求,为其提供量身定制的解决方案。</p>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </GridColumn>
                </GridRow>


                <GridRow textAlign='center' className="background-row">
                    <GridColumn>
                        <p>&nbsp;</p>
                        <h1>系统展示</h1>
                    </GridColumn>
                </GridRow>
                <GridRow textAlign='center' className="background-row">
                  <GridColumn width={16} centered>
                    <PageScenariosLowCode />
                  </GridColumn>
                </GridRow>

            </Grid>
        </Container>

    )
}

export default LowCode;
