import React, {useState} from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {change_myself_password} from "../../api/profile";
import {Container, Header} from "semantic-ui-react";
import {is_login, remove_auth} from "../../common/auth_utils";

const ChangePasswordMyselfPage = props => {

    const navigate = useNavigate();

    const params = useParams();

    const [state, setState] = useState({
        msg: '',
        ok: null
    });

    const handleSubmit = (data) => {
        let s_data = {...data};
        if (is_login()){
            change_myself_password(s_data).then(res => {
                const {ok, msg} = res;
                if (ok) {
                    remove_auth();
                    navigate(-1);
                } else {
                    toast(msg);
                    setState({ok: ok, msg: msg});
                }
            });
        }
    };

    const {ok, msg} = state;

    return (
        <Container style={{height:'620px'}}>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <Header>用户自行修改密码</Header>
            <ChangePasswordForm
                verifyoldpassword={true}
                id={params.id}
                ok={ok}
                msg={msg}
                style={{height: 'calc(100vh - 88px)'}}
                handleSubmit={handleSubmit}
                link_return={-1}
            />
        </Container>
    )
}

export default ChangePasswordMyselfPage;
