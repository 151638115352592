import React, {useState} from "react";
import {InView} from "react-intersection-observer";
import {Media} from "./MediaContext";
import PubHeader from "./PubHeader";

export const DesktopContainer = props => {

    const {children} = props;
    const [fixed, toggleFixedMenu] = useState(false);

    return (
        <Media greaterThan='mobile'>
            <InView onChange={toggleFixedMenu}>
                <PubHeader/>
                {/*<Menu
                        fixed={fixed ? 'top' : null}
                        inverted={!fixed}
                        pointing={!fixed}
                        secondary={!fixed}
                        size='large'>
                        <Container>
                            <Menu.Item as='a' active>
                                Home
                            </Menu.Item>
                            <Menu.Item as='a'>Work</Menu.Item>
                            <Menu.Item as='a'>Company</Menu.Item>
                            <Menu.Item as='a'>Careers</Menu.Item>
                            <Menu.Item position='right'>
                                <Button as='a' inverted={!fixed}>
                                    Log in
                                </Button>
                                <Button as='a' inverted={!fixed} primary={fixed} style={{marginLeft: '0.5em'}}>
                                    Sign Up
                                </Button>
                            </Menu.Item>
                        </Container>
                    </Menu>*/}
                {/*<HomepageHeading/>*/}
            </InView>

            {children}
        </Media>
    )
}