import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {is_login} from "../common/auth_utils";
import {observer} from "mobx-react-lite";
import {getQueryParam} from "../common/search_params";

const PrivateRoute = (props) => {
    const {children} = props

    const islogin = is_login() || false;
    const nav = useNavigate();
    const code = getQueryParam("code");
    // console.log(code)
    const login_url_params = code ? ("?code=" + code) : "";
    useEffect(() => {

        // console.log(islogin, code, login_url_params)
        if (!islogin && code) {
            nav('/checklogin' + login_url_params, {replace: true});
        }
    }, [code,]);


    // const [params] = useSearchParams();
    //
    // const code = params.get('code');
    // // console.log(code)
    // const login_url_params = code ? ("?" + code) : "";
    return (
        <>{children}</>
    )
}

export default observer(PrivateRoute);
