import React from "react";
import ModuleForm from "./ModuleForm";


const ModuleAddPage = (props) => {
    let title = (
        <h3>
            正在新增：<span style={{color: "green"}}>【模块信息】</span>
        </h3>);

    return (
        <ModuleForm title={title} />
    );
};

export default ModuleAddPage;
