import axios from "axios";
// const AUTH_PATH = `${process.env.REACT_APP_AUTHPATH}`
const AUTH_PATH = `${process.env.REACT_APP_SSO}/api`
export const oauth2_get_token = (code: string, redirect_uri) => {
    return axios.get(`${AUTH_PATH}/oauth2/gettoken?code=${code}&redirect_uri=${redirect_uri}`, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
}

export const oauth2_refresh_token = (refresh_token: string) => {
    return axios.get(`${AUTH_PATH}/oauth2/refreshtoken?refresh_token=${refresh_token}`, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
}