import React, {useEffect} from 'react'
import {Button, Container, Divider, Form, Header, Message, Segment} from 'semantic-ui-react'
import {useNavigate, useSearchParams} from "react-router-dom"
import {toast} from "react-toastify";
import {is_login, remove_auth, store_auth} from "../../common/auth_utils";
import {oauth2_get_token} from "../../api/token";

const CheckLoginPage = () => {

    const navigate = useNavigate();
    const [params] = useSearchParams();

    const code = params.get("code");
    // console.log(params, code)

    useEffect(() => {
        if (code) {
            // console.log(code)
            return;
        }

        // globalstate.setLoginUrl(location.pathname || '');
        const islogin = is_login() || false;
        // console.log(islogin)
        if (!islogin || !code) {
            // navigate("/")
            remove_auth();
            // window.location.href = LOGIN_URL;
            return;
        }

    }, [code,]);

    return (
        <>
            <Container>
                <div style={{height: '100px'}}></div>
                <Header as='h2' color='teal' textAlign='center'>
                    {/*<Image src='/logo.png'/> */}
                    授权码确认
                </Header>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    if (!code) {
                        toast("缺少授权码，请重新登录！");
                        return;
                    }
                    oauth2_get_token(code,`${process.env.REACT_APP_REDIRECTURI}`).then(r => {
                        // console.log(JSON.stringify(r))
                        const {status, statusText, data} = r;
                        if (status === 200 && data?.ok !== false) {
                            const {access_token, refresh_token, expires_in} = data;
                            // console.log(access_token, refresh_token, expires_in, data);
                            store_auth(access_token, expires_in);
                            // console.log(is_login())
                            // toast("授权成功！")
                            window.location.href = "/";
                            // navigate("/");
                        } else {
                            // console.log(statusText, data);
                            if (statusText === "ok" && !data?.ok)
                                toast(data?.msg);
                            else
                                toast(statusText);
                        }
                    }, err=>toast(err.message));
                }}>
                    <>
                        <Form.Input fluid icon='lock' iconPosition='left'
                                    label={"授权码："} placeholder='授权码'
                                    value={code || ""}
                                    required={true}/>

                        <Form.Field style={{color: "red"}}>上述授权码确认后，申请访问令牌。此授权码只可使用一次！</Form.Field>


                        <Button color='teal' fluid size='large'>
                            确定
                        </Button>
                    </>
                    <Message error>
                        出错！
                    </Message>
                </Form>
                <Divider hidden/>
                <Divider hidden/>
                <Divider hidden/>
                <Divider hidden/>
            </Container>
        </>
    )
}

export default CheckLoginPage;