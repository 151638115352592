import React from "react";
import {useRoutes} from "react-router-dom";
import {NotFound} from "./NotFound";
import PrivateRoute from "../layout/PrivateRoute";
import {Footer} from "../layout/Footer";
import ChangePasswordMyselfPage from "./security/ChangePasswordMyselfPage";
import ProfilePage from "./security/ProfilePage";
import CheckLoginPage from "./security/CheckLoginPage";
import PreLoginPage from "./security/PreLoginPage";
import ModuleMain from "./module/ModuleMain";
import ModuleAddPage from "./module/ModuleAddPage";
import ModuleSavePage from "./module/ModuleSavePage";
import ContactUs from "./ContactUs";
import DataBusinessGovernment from "./DataBusinessGovernment";
import PerfModule from "./PerfModule";
import GovOpenModule from "./GovOpenModule";
import HomePage from "./home/HomePage";
import InquiryInfo from "./security/InquiryInfo";
import {ToastContainer} from "react-toastify";
import {ResponsiveContainer} from "../layout/ResponsiveContainer";
import "react-toastify/dist/ReactToastify.min.css"
import LowCode from "./LowCode";
export const MainRoutes = (props) => {

    /*
    return (
        <MainRoutes>
            <Route path="/" element={<Home/>}/>
            <Route path="dashboard/!*" element={<Dashboard/>}/>
        </MainRoutes>
    )*/
    let element = useRoutes([
        {path: '/', element: <PerfModule/>},
        {path: '/prelogin', element: <PreLoginPage/>},
        {path: '/checklogin', element: <CheckLoginPage/>},
        {path: '/changemypasswd', element: <PrivateRoute><ChangePasswordMyselfPage/></PrivateRoute>},
        {path: '/profile', element: <PrivateRoute><ProfilePage/></PrivateRoute>},
        {path: '/home', element: <HomePage/>},
        {path: '/dbg', element: <DataBusinessGovernment/>},
        {path: '/pfs', element: <PerfModule/>},
        {path: '/govopen', element: <GovOpenModule/>},
        {path: '/lowcode', element: <LowCode/>},
        {path: '/contact-us', element: <ContactUs/>},
        {path: '/inquiryinfo', element: <PrivateRoute><InquiryInfo/></PrivateRoute>},
        {
            children: [
                {path: 'module', element: <PrivateRoute><ModuleMain/></PrivateRoute>},
                {path: 'module/addnew', element: <PrivateRoute><ModuleAddPage/></PrivateRoute>},
                {path: 'module/edit/:id', element: <PrivateRoute><ModuleSavePage/></PrivateRoute>},
            ]
        },
        // 重定向
        {path: '/home', redirectTo: '/'},
        // 404找不到
        {path: '*', element: <NotFound/>}
    ]);
    return (
        <>
            <ToastContainer/>
            <ResponsiveContainer>
                <div style={{height: "calc(100vh - 60px)", overflow: "scroll"}}>
                    {element}
                    <Footer/>
                </div>
            </ResponsiveContainer>
        </>
    )
}
