import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  flex: 1;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: ${(props) => (props.active ? '#007bff' : '#f1f1f1')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 2rem;
`;

const ImageWrapper = styled.div`
  flex: 0 0 75%;
  margin-right: 2rem;

//    @media (max-width: 768px) {
//     flex: 0 0 auto;
//     margin-right: 0;
//     margin-bottom: 1rem;
//   }
//
//   img {
//     max-width: 100%;
//     height: auto;
//   }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextWrapper = styled.div`
  flex: 0 0 25%;
  text-align: left;
  background: linear-gradient(to right, #fcfcfc, #efefef);
  padding: 2rem;
`;




const PageScenariosLowCode = () => {
  const [activeScenario, setActiveScenario] = useState('finance');

  const handleScenarioClick = (scenario) => {
    setActiveScenario(scenario);
  };

  const scenarios = {
    finance: {
      title: '套表定义',
      content: '我司搭建了自己的低代码工具，针对不同的业务需求，无需改动代码，快速实现功能，达到客户的要求。',
      image: `${process.env.PUBLIC_URL}/images/lowcode/view_01.png`,
    },
    transportation: {
      title: '表样预览',
      content: '通过预览表格把控整体方向。',
      image: `${process.env.PUBLIC_URL}/images/lowcode/view_show.png`,

    },
    ecommerce: {
      title: '多租户管理',
      content: '我司拥有自主研发的多租户后台管理系统，实现了数据的隔离，避免发生数据丢失的连锁反应等问题，方便数据的持续利用。',
      image: `${process.env.PUBLIC_URL}/images/dbg/photo3.png`,

    },
  };

  return (
    <Wrapper>
      <ButtonGroup>
        {Object.keys(scenarios).map((scenario) => (
          <Button
            key={scenario}
            onClick={() => handleScenarioClick(scenario)}
            active={activeScenario === scenario}
          >
            {scenarios[scenario].title}
          </Button>
        ))}
      </ButtonGroup>
     <ContentRow>
      <ImageWrapper>
        <Image src={scenarios[activeScenario].image} alt="Example" />
      </ImageWrapper>
      <TextWrapper>
        <h2>{scenarios[activeScenario].title}</h2>
        <p>{scenarios[activeScenario].content}</p>
      </TextWrapper>
    </ContentRow>
    </Wrapper>
  );
};

export default PageScenariosLowCode;