import {makeAutoObservable} from "mobx";
import {get_access_token} from "../common/auth_utils";
import http, {post} from "../common/http";
import qs from "qs";

class Login {
    //当token没有的时候，给他一个空值
    token = get_access_token() || ''

    constructor() {
        makeAutoObservable(this)
    }

    //发送登录请求
    toLogin = async ({username, password}) => {
        await http("post", '/oauth/login', qs.stringify({username, password}),
            {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'})
            .then(function (res: { token: '' }) {
                this.token = res.token;
                // 存token
                // storeToken(this.token)
            });
    }
    //清空token
    loginOut = () => {
        this.token = ''
        // removeToken()
    }
}

export default Login;

