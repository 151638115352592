import {makeAutoObservable} from "mobx";
import {get_loginuser, remove_auth, store_loginuser} from "../common/auth_utils";

class GlobalState {
    sidebarStatic = false;
    sidebarOpened = false;
    dashboardTheme = "dark";
    activeItem = '';

    theme = '';
    sidebarColor = '';
    navbarColor = '';
    navbarType = '';
    sidebarType = '';

    private loginUser = {
        realname: "",
        username: "",

    };

    rolecodes = new Set<string>();

    authorities = new Set<string>();


    constructor() {
        makeAutoObservable(this)
    }

    public clearLoginUser() {
        this.loginUser.realname = "";
        this.loginUser.username = "";
        this.rolecodes.clear();
        this.authorities.clear();
    }

    public setLoginUser({username, realname, rolecodes=[], authorities=[]}) {
        this.loginUser.realname = realname;
        this.loginUser.username = username;
        rolecodes.forEach(item => {
            // console.log(item)
            this.rolecodes.add(item);
        })
        authorities.forEach(item => {
            // console.log(item)
            this.authorities.add(item);
        })
        store_loginuser(this.loginUser)
    }

    public getLoginUser() {
        return this.loginUser ? this.loginUser : get_loginuser();
    }

    public hasRole(rolecode: string) {
        return this.rolecodes?.has(rolecode) === true;
    }

    public hasAuthority(perm: string) {
        return this?.authorities?.has(perm) === true;
    }

    public doLogout = () => {
        // this.props.dispatch(logoutUser());
        this.clearLoginUser();
        remove_auth();
        window.location.href = '/';
    }

    public toggleSiderbar = () => {
        this.sidebarStatic = !this.sidebarStatic;
    }

    openSiderbar = () => {
        this.sidebarOpened = true;
    }

    closeSiderbar = () => {
        this.sidebarOpened = false;
    }

    changeActiveSidebarItem = (item) => {
        this.activeItem = item;
    }

    changeTheme = (val) => {
        this.theme = val;
    }

    changeSidebarColor = (val) => {
        this.sidebarColor = val;
    }

    navbarTypeToggle = (val) => {
        this.navbarType = val;
    }

    sidebarTypeToggle = (val) => {
        this.sidebarType = val;
    }

    changeNavbarColor = (val) => {
        this.navbarColor = val;
    }

    handleResize = () => {
        if (window.innerWidth <= 768 && this.sidebarStatic) {
            this.sidebarStatic = !this.sidebarStatic;
        }
    }

    handleSwipe = (e) => {
        if ('ontouchstart' in window) {
            if (e.direction === 4) {
                this.sidebarOpened = true;
                return;
            }

            if (e.direction === 2 && this.sidebarOpened) {
                this.sidebarOpened = false;
                return;
            }
        }
    }

}

export default GlobalState;