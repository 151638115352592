import React, {useEffect} from 'react'
import {Button, Container, Divider, Form, Segment} from 'semantic-ui-react'
import {useSearchParams} from "react-router-dom"
import {is_login} from "../../common/auth_utils";

const LOGIN_URL = `${process.env.REACT_APP_SSO}/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENTID}&scope=all&redirect_uri=${process.env.REACT_APP_REDIRECTURI}`;

const PreLoginPage = () => {

    const [params] = useSearchParams();
    const code = params.get("code");

    useEffect(() => {

        // globalstate.setLoginUrl(location.pathname || '');
        const islogin = is_login() || false;
        // console.log(islogin, code, get_auth())
        if (islogin) {
            window.location.href = "/";
            return;
        }

    }, [code,]);

    return (
        <>
            <Container fluid textAlign={"center"} style={{width: '40%'}}>
                <div style={{height: '100px'}}></div>
                <Segment stacked>
                    <Form.Field style={{color: "red"}}>您还完成授权认证，请点击去完成授权！</Form.Field>
                    <Divider/>
                    <Button type={"button"} color='red'
                            size='small'
                            onClick={event => {
                                event.preventDefault();
                                // if (!window.confirm("确定去完成授权认证（登录）？")) return;
                                window.location.href = LOGIN_URL;
                            }}>
                        授&nbsp;&nbsp;权
                    </Button>
                </Segment>
            </Container>
        </>
    )
}

export default PreLoginPage;