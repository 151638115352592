import http from '../common/http';
import qs from "qs";

///模块（子系统）数据，随着接入点增多，条数可能比较多，故模糊和分页
const module_get_page_items = (querystr, sortfield, sortdir, pagenum = 1, pagesize = 30) => {
    return http("GET", '/api/module/page', {querystr, sortfield, sortdir, pagenum, pagesize},
        {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'});
}

const module_delete_one = (id: string) => {
    return http("POST", '/api/module/delbyid', qs.stringify({id: id}),
        {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'});
}

const module_load_by_id = (id: string) => {
    return http("GET", '/api/module/load', {id: id},
        {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'});
}

const module_save_one = (obj: {}) => {
    return http("POST", '/api/module/save', obj);
}

export {
    module_get_page_items, module_delete_one, module_load_by_id, module_save_one
}


