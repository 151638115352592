import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  flex: 1;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: ${(props) => (props.active ? '#007bff' : '#f1f1f1')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 2rem;
`;

const ImageWrapper = styled.div`
  flex: 0 0 75%;
  margin-right: 2rem;

//    @media (max-width: 768px) {
//     flex: 0 0 auto;
//     margin-right: 0;
//     margin-bottom: 1rem;
//   }
//
//   img {
//     max-width: 100%;
//     height: auto;
//   }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextWrapper = styled.div`
  flex: 0 0 25%;
  text-align: left;
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  padding: 2rem;
`;




const PageScenariosDbg = () => {
  const [activeScenario, setActiveScenario] = useState('finance');

  const handleScenarioClick = (scenario) => {
    setActiveScenario(scenario);
  };

  const scenarios = {
    finance: {
      title: '数据报表和可视化',
      content: '适用于业务数据的整理、加工和汇总查询。我们将主要业务梳理为一套自定义报表，可以将业务数据迁移到设计好的报表，实现数据的一键式查询和可视化。无需再到生产系统中逐个业务查询。',
      image: `${process.env.PUBLIC_URL}/images/dbg/photo1.png`,
    },
    transportation: {
      title: '数据填报和报送',
      content: '适用于政府部门、大型集团的部门数据填报和报送。上级将设计好的报表任务下发到各业务部门，部门填报完成并审核通过后上报并锁定。上级可按单位查看填报情况，也可以汇总查询、自定义查询个别数据项。',
      image: `${process.env.PUBLIC_URL}/images/dbg/photo2.png`,

    },
    ecommerce: {
      title: '报告生成',
      content: '适用于需要产生特定结果（报告）的场景，比如财政部门的部门预决算公开报告、质检机构的认证证书等。通过自定义取数（条件取数、条件计算），很容易从报表中提取指定数据，来渲染模板，产生最终报告。通过数据表自定义、自定义审核公式、取数自定义、报告自定义可以实现大部分复杂要求的报告输出。',
      image: `${process.env.PUBLIC_URL}/images/dbg/photo3.png`,

    },
  };

  return (
    <Wrapper>
      <ButtonGroup>
        {Object.keys(scenarios).map((scenario) => (
          <Button
            key={scenario}
            onClick={() => handleScenarioClick(scenario)}
            active={activeScenario === scenario}
          >
            {scenarios[scenario].title}
          </Button>
        ))}
      </ButtonGroup>
     <ContentRow>
      <ImageWrapper>
        <Image src={scenarios[activeScenario].image} alt="Example" />
      </ImageWrapper>
      <TextWrapper>
        <h2>{scenarios[activeScenario].title}</h2>
        <p>{scenarios[activeScenario].content}</p>
      </TextWrapper>
    </ContentRow>
    </Wrapper>
  );
};

export default PageScenariosDbg;