import React from "react";
import {useParams} from "react-router-dom";
import ModuleForm from "./ModuleForm";


const ModuleSavePage = (props) => {

    let params = useParams();
    let id = params?.id || '';

    let title = (
        <h3>
            正在编辑：<span style={{color: "green"}}>【模块信息】</span>
        </h3>);

    return (
        <ModuleForm title={title} id={id}/>
    );
};

export default ModuleSavePage;
