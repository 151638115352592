import React, {useEffect, useState} from "react";
import {Button, Form} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import ModuleTable from "./ModuleTable";
import {toast} from "react-toastify";
import {module_get_page_items} from "../../api/module";

const DOMAIN_NAME = "/admin/module";
const ModuleMain = props => {

    const nav = useNavigate();

    const [sort, setSort] = useState({
        sortfield: 'modulename',
        sortdir: 'asc'
    });

    const [page, setPage] = useState({
        pageable: true,
        items: [],
        totalElements: 0,
        totalPage: 0,
        activePage: 1,
        pageSize: 20,
    })

    const [rand, setRand] = useState(0);

    useEffect(() => {
        module_get_page_items(null, sort.sortfield, sort.sortdir, page.activePage, page.pageSize).then(
            (data) => {
                setPage({
                    ...page, items: data.content,
                    totalElements: data.totalElements,
                    totalPage: data.totalPages, activePage: data.number + 1
                });
            },
            (error) => {
                toast(error.message);
            }
        );
    }, [rand]);

    return (
        <>
            <Form.Group inline>
                <Button size={"tiny"} primary onClick={() => {
                    nav(`${DOMAIN_NAME}/addnew`);
                }}>新增</Button>
            </Form.Group>
            <ModuleTable
                height={"calc(100vh - 140px)"}
                setRand={setRand} sort={sort}
                columnSort={(field, dir) => {
                    setRand(Math.random() * 10000);
                    setSort({sortfield: field, sortdir: dir});
                }}
                page={page}
                onPageChange={(activePage, totalPage, pageSize) => {
                    setRand(Math.random() * 10000);
                    setPage({...page, activePage, totalPage, pageSize});
                }}/>
        </>
    )
}
export default ModuleMain;
