import React from "react";
import {Container, Grid, GridColumn, GridRow, Header, Image} from "semantic-ui-react";
import '../css/govOpen.css'
import PageScenariosGovOpen from './PageScenarios/PageScenariosGovOpen';

const PUBLIC_URL = process.env.PUBLIC_URL
function GovOpenModule() {
    return (
        <Container fluid>
            <Grid>



                <GridRow textAlign='center' className='gov_open'>
                    <GridColumn>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Header as='h1' inverted>提供完整的预决算公开解决方案<br/>从数据填报开始，提供技术支持，检查服务和跟踪服务。</Header>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </GridColumn>
                </GridRow>


                <GridRow>
                  <GridColumn width={16}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ maxWidth: '1400px', width: '100%' }}>
                        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                          <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>产品优势</h2>
                          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp1.png`} alt="Advantage 1" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">自定义报表</h3>
                                <p>预决算公开报表每年都在变化、每个地区表样不一样，填报的内容也有所区别。通过自定义报表来实现,并对现有报表进行扩展，使得可以填报上年数、原因说明、用途等信息。</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp3.png`} alt="Advantage 3" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">自定义审核</h3>
                                <p>通过自定义审核公式来控制数据质量。支持复杂审核，比如表间、科目平衡审核等。用户创建报告之前要求必须通过审核。</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp2.png`} alt="Advantage 2" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">任务流程</h3>
                                <p>任务下发、填报、上报、退回，完整的管理流程，支持批量操作，支持按单位查看。</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp5.png`} alt="Advantage 5" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">报告跟踪</h3>
                                <p>要求报告公开后，回填公开网址，我们通过专用系统实现对报告的跟踪（包括版本比对）。</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp6.png`} alt="Advantage 4" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">一键创建报告</h3>
                                <p>自定义word模板、自定义取数，创建报告时自动从表中取数。</p>
                              </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '40px', width: '30%' }}>
                              <img src={`${process.env.PUBLIC_URL}/images/lowcode/tp4.png`} alt="Advantage 6" className='image_min' />
                              <div style={{marginLeft: '30px'}}>
                                <h3 className="product_ad_h3">新型服务模式</h3>
                                <p>我们提供培训、在线问题解答、事后检查、网址跟踪等服务，确保服务质量。</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </GridColumn>
                </GridRow>


                <GridRow textAlign='center' className="background-row">
                    <GridColumn>
                        <p>&nbsp;</p>
                        <h1>适应场景</h1>
                    </GridColumn>
                </GridRow>
                <GridRow textAlign='center' className="background-row">
                    <GridColumn width={16} centered>
                        <PageScenariosGovOpen />
                    </GridColumn>
                </GridRow>

                 {/*/!*<div style={{backgroundColor:'#FBFCFF' ,paddingTop:'5em'}}> *!/*/}

                 {/*     <div style={{textAlign:'center',paddingBottom:'4rem'}}> *!/*/}
                 {/*<GridRow textAlign='center'> */}
                 {/*    <GridColumn> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <Header as='h1'>自定义模板 */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            极其灵活的业务系统可以满足地区单位的个性化需求 */}

                 {/*        </Header> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*    </GridColumn> */}
                 {/*</GridRow> */}
                 {/*/!*</div> *!/*/}

                 {/*<Grid.Row columns={4}> */}
                 {/*    <GridColumn width={1}></GridColumn> */}
                 {/*    <GridColumn width={6}> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>&nbsp;&nbsp;根据省标准套表和公开模板要求，为各个区县配制专属模板</content> */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>新增绩效情况表、下属单位表、单位职能表等。完整涵盖了模板所需的全部数据</content> */}
                 {/*        </Header> */}
                 {/*    </GridColumn> */}
                 {/*    <GridColumn width={7}> */}
                 {/*        <Image centered rounded floated='left' src={`${PUBLIC_URL}/images/gov_open/gov-open-1.png`} className='image_fixed'/> */}
                 {/*    </GridColumn> */}
                 {/*    <GridColumn width={1}></GridColumn> */}

                 {/*</Grid.Row> */}
                 {/*/!*</div> *!/*/}

                 {/*<GridRow textAlign='center'> */}
                 {/*    <GridColumn> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <Header as='h1'>快速生成报告 */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            最快一个小时内即可生成一份完整的报告，大大节约了时间和人力成本 */}
                 {/*        </Header> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*    </GridColumn> */}
                 {/*</GridRow> */}


                 {/*<Grid.Row columns={4}> */}
                 {/*    <GridColumn width={1}></GridColumn> */}

                 {/*    <GridColumn width={7}> */}
                 {/*        <Image centered rounded src={`${PUBLIC_URL}/images/gov_open/gov-open-2.png`} className='image_fixed'/> */}
                 {/*    </GridColumn> */}
                 {/*    <GridColumn width={6}> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>根据客户需求，线上或线下培训，支持上千家单位同时填报。</content> */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>根据各个地区口径不同，可自定义审核公式，无需修改代码即可实现，提高报告整体质量。</content> */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>审核无误后，即可一键创建报告，后续修改也只需删除原报告，重新创建，即可生成一份全新的报告。</content> */}
                 {/*        </Header> */}
                 {/*    </GridColumn> */}
                 {/*    <GridColumn width={1}></GridColumn> */}

                 {/*</Grid.Row> */}

                 {/*<GridRow textAlign='center'> */}
                 {/*    <GridColumn> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <Header as='h1'>持续跟踪服务 */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            我司会对单位生成的报告进行多轮检查，以及对公开状态的追踪，为客户提供最完善的服务。 */}
                 {/*        </Header> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*    </GridColumn> */}
                 {/*</GridRow> */}


                 {/*<Grid.Row columns={4}> */}
                 {/*    <GridColumn width={1}></GridColumn> */}
                 {/*    <GridColumn width={6}> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>报告完成后，后续会对报告中进行多轮的检查核对，并给各单位发送问题报告，以防出现填写的不合理、引用文件有误等问题。</content> */}
                 {/*        </Header> */}
                 {/*        <Header as='h3'> */}
                 {/*            <Image src={`${PUBLIC_URL}/images/gov_open/icon.png`} size='mini'/> */}
                 {/*            <content>我们拥有自主研发的报告跟踪系统，会持续对客户的报告挂网情况进行跟踪及版本比对，避免出现公开不及时，网址失效等情况。</content> */}
                 {/*        </Header> */}
                 {/*    </GridColumn> */}
                 {/*    <GridColumn width={7}> */}
                 {/*        <Image centered rounded floated='left' src={`${PUBLIC_URL}/images/gov_open/gov-open-3.png`} className='image_fixed'/> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*        <p>&nbsp;</p> */}
                 {/*    </GridColumn> */}
                 {/*    <GridColumn width={1}></GridColumn> */}
                 {/*</Grid.Row> */}
            </Grid>
        </Container>
    )
}

export default GovOpenModule;