import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Header, Message,} from 'semantic-ui-react';

import {useNavigate} from "react-router-dom";
import {user_load_profile, user_save_profile} from "../../api/profile";
import {is_login} from "../../common/auth_utils";

const ProfilePage = props => {

    const navigate = useNavigate();

    const [status, setStatus] = useState({
        ok: null,
        msg: '',
    });

    const [obj, setObj] = useState({
        username: '',
        realname: '',
        email: '',
        tel: '',
        remark: ''
    });

    const handleChange = ({name, value}) => {
        // console.log(name,value)
        setObj({...obj, [name]: value});
    };

    useEffect(() => {
        if (is_login()){
            user_load_profile().then(res => {
                // console.log(res)
                const {ok, msg, data} = res;
                setStatus({ok, msg});
                if (ok) {
                    const {username, realname, email, tel, remark} = data;
                    setObj({username, realname, email, tel, remark});
                }
            });
        }
    }, []);

    const onSubmit = (data) => {
        const {
            username, realname, tel, email, remark
        } = data;
        user_save_profile({username, realname, tel, email, remark}).then(res => {
            const {ok, msg, data} = res;
            setStatus({ok, msg});
        });
    }

    return (
        <Container>
            <p>&nbsp;</p>
            <Header>{"正在编辑个人信息"}</Header>
            <Form error={status.ok === false}
                  success={status.ok === true&& !!status.msg}
                  onSubmit={(e) => {
                      e.preventDefault();
                      onSubmit(obj);
                  }}>
                <Form.Input label={"用户名"}
                            readOnly
                            name={"username"}
                            value={obj.username || ''}/>
                <Form.Input label={"姓名"}
                            value={obj.realname || ''}
                            name={"realname"}
                            onChange={e => handleChange(e.target)}/>
                <Form.Input type={"tel"} label={"电话号码"}
                            value={obj.tel || ''}
                            name={"tel"}
                            onChange={e => handleChange(e.target)}/>
                <Form.Input type={"email"} label={"电子邮箱"}
                            value={obj.email || ''}
                            name={"email"}
                            onChange={e => handleChange(e.target)}/>

                <Form.TextArea
                    label={"备注"}
                    name="remark"
                    rows={5}
                    placeholder="请填写备注信息"
                    value={obj.remark || ''}
                    onChange={e => handleChange(e.target)}/>

                <Message success content={status.msg}></Message>
                <Message error content={status.msg}></Message>
                <Form.Group>
                    <Button type={"submit"} primary>保存</Button>
                    <Button type={"button"} secondary onClick={event => navigate(-1)}>返回</Button>
                </Form.Group>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Form>

        </Container>
    )
}

export default ProfilePage;
