import React from "react";
import './App.css';
import {MainRoutes} from "./page/MainRoutes";
import "semantic-ui-css/semantic.min.css"

function App() {
    return (
        <>
            <MainRoutes/>
        </>
    )
}

export default App;
