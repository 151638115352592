import {MediaContextProvider} from "./MediaContext";
import {DesktopContainer} from "./DesktopContainer";
import {MobileContainer} from "./MobileContainer";

export const ResponsiveContainer = ({ children }) => (
    /* Heads up!
     * For large applications it may not be best option to put all page into these containers at
     * they will be rendered twice for SSR.
     */
    <MediaContextProvider>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
)
