import React, {useEffect, useState} from "react";
import {Image, Menu, MenuMenu} from "semantic-ui-react";
import {is_login} from "../common/auth_utils";
import {user_load_profile} from "../api/profile"
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useStore} from "../store";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
// let className = ``;
const PubHeader = () => {

    let nav = useNavigate();
    const {globalstate} = useStore();

    const islogin = is_login() || false;

    const user = globalstate.getLoginUser();

    const [currentPage, setCurrentPage] = useState(() => {
        const storedPage = localStorage.getItem('currentPage');
        return storedPage ? storedPage : 'pfs';
    });
    const handleMenuClick = (page) => {
        localStorage.removeItem('currentPage');
        setCurrentPage(page);
        localStorage.setItem('currentPage', page);
    };
    useEffect(() => {
        if (islogin) {
            user_load_profile().then(res => {
                const {ok, msg, data} = res;
                if (ok) {
                    const {username, realname, userroles = [], authorities = {}} = data;
                    let tmp_rolecodes = [], tmp_authorities = [];
                    // console.log(userroles, authorities)
                    userroles.forEach(item => tmp_rolecodes.push(item.rolecode));
                    authorities.forEach(item => {
                        tmp_authorities.push(item['authority']);
                    });
                    globalstate.clearLoginUser();
                    // console.log(tmp_authorities, tmp_rolecodes)
                    globalstate.setLoginUser({
                        username,
                        realname,
                        rolecodes: tmp_rolecodes,
                        authorities: tmp_authorities
                    });
                } else {
                    toast(msg);
                }

            }, err => {
                toast(err.message);

            });
        }

    }, [islogin, currentPage]);

    const PUBLIC_URL = process.env.PUBLIC_URL

    return (
        <div style={{height: "80px", width: '100vw', backgroundColor: '#f6f8f9'}}>
            <Menu borderless fixed={"top"} inverted={false} style={{justifyContent: "flex-start", height: "80px"}}
                  secondary>
                <Image style={{paddingLeft: '5rem', height: '80px', paddingTop: '10px'}}
                       src={`${PUBLIC_URL}/images/logos-blue.png`}/>
                <Menu.Item as={'a'} header={true} href={"/#/home"} position={"left"}
                           style={{color: currentPage === 'home' ? 'blue' : 'black'}}
                           onClick={() => handleMenuClick('home')}>
                    首页
                </Menu.Item>
                <Menu.Item as={'a'} header={true} href={"/#/pfs"} position={"left"}
                           style={{color: currentPage === 'pfs' ? 'blue' : 'black'}}
                           onClick={() => handleMenuClick('pfs')}>
                    企业绩效管理

                </Menu.Item>
                <Menu.Item as={'a'} header={true} href={"/#/dbg"} position={"left"}
                           style={{color: currentPage === 'dbg' ? 'blue' : 'black'}}
                           onClick={() => handleMenuClick('dbg')}>
                    数据治理平台
                </Menu.Item>
                <Menu.Item as={'a'} header={true} href={"/#/lowcode"} position={"left"}
                           style={{color: currentPage === 'lowcode' ? 'blue' : 'black'}}
                           onClick={() => handleMenuClick('lowcode')}>
                    低代码开发
                </Menu.Item>
                <Menu.Item as={'a'} header={true} href={"/#/govopen"} position={"left"}
                           style={{color: currentPage === 'govopen' ? 'blue' : 'black'}}
                           onClick={() => handleMenuClick('govopen')}>
                    预决算公开服务
                </Menu.Item>
                <Menu.Item as={'a'} header={true} href={"/#/contact-us"} position={"left"}
                           style={{color: currentPage === 'contact-us' ? 'blue' : 'black'}}
                           onClick={() => handleMenuClick('contact-us')}>

                    联系我们
                </Menu.Item>
                {/*<Menu.Item as={'a'} header={true} style={{marginLeft: "50px"}}>*/}
                {/*    &nbsp;*/}
                {/*    {*/}
                {/*        <Link style={{color: "black"}} to={"/"}>部门绩效项目云</Link>*/}
                {/*    }*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item as={'a'} header>*/}
                {/*    &nbsp;*/}
                {/*    {*/}
                {/*        islogin && <></>*/}
                {/*    }*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item as={'a'} header onClick={event =>{*/}
                {/*    event.preventDefault();*/}
                {/*    nav("/admin/module");*/}
                {/*}} >*/}
                {/*    /!* <Link to={null} onClick={event=>{*/}
                {/*       nav("/admin/module");*/}
                {/*   }}>测试模块</Link>*!/*/}
                {/*    <span style={{color: "red"}}>测试模块</span>*/}
                {/*</Menu.Item>*/}

                <MenuMenu position='right'>
                    {
                        !islogin &&
                        <Menu.Item header={true} style={{color: 'black'}} position="right" onClick={event => {
                            event.preventDefault();
                            const LOGIN_URL = `${process.env.REACT_APP_SSO}/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENTID}&scope=all&redirect_uri=${process.env.REACT_APP_REDIRECTURI}`;
                            window.location.href = LOGIN_URL;
                        }}>
                            管理员登录
                        </Menu.Item>
                    }

                    <Menu.Item as={'a'} header style={{display: is_login() ? 'black' : 'none'}}>
                        {/*&nbsp;*/}
                        {/*{islogin &&*/}
                        <>
                            <Menu.Item as='a'>
                                <Link to={"/changemypasswd"}>修改密码</Link>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Link to={"/profile"}>账号信息</Link>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Link to={"/inquiryinfo"}>咨询信息</Link>
                            </Menu.Item>
                            <Menu.Item as={'a'} header>
                                {/*&nbsp;*/}
                                {/*{*/}
                                {/*    islogin &&*/}
                                <>
                                    <span
                                        className={"light"}>用户名：{user?.username}，姓名：{user?.realname}</span> &nbsp;&nbsp;
                                    <NavLink to={null} style={{color: 'red'}} onClick={event => {
                                        event.preventDefault();
                                        globalstate.doLogout();
                                    }}>退出</NavLink>
                                </>
                                {/*}*/}
                            </Menu.Item>
                        </>
                        {/*}*/}
                    </Menu.Item>
                </MenuMenu>

            </Menu>
        </div>
    )
}

export default observer(PubHeader);