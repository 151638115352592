import http from '../common/http';

const inquiry_submit_one = (obj: any) => {
    return http("POST", '/api/inquiry/submit', obj);
}

const inquiry_get_all_items = (querystr, sortfield, sortdir, pagenum, size) => {
    return http("GET", '/api/inquiry/findby', {querystr, sortfield, sortdir, pagenum: pagenum, pagesize: size},
        {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'});
}

const inquiry_del_by_id = (id: string) => {
    return http("GET", '/api/inquiry/delbyid', {id:id});
}


export {
    inquiry_submit_one, inquiry_get_all_items,inquiry_del_by_id
}